export class UtilsFiles {
    public static CreateURLFromBase64(axios_response: string, type: string): string {
        const byteCharacters = atob(axios_response);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        //Create a Blob from Stream
        const file = new Blob(
            [byteArray],
            { type: type });
        //Build a URL from the file
        return window.URL.createObjectURL(file);
    }

    public static CreateURLFromBase64AndOpen(axios_response: string, type: string) {
        let link = document.createElement('a');
        link.href = UtilsFiles.CreateURLFromBase64(axios_response, type);
        link.download = UtilsFiles.generateGuid()+'.pdf';
        link.click();
        //Open the URL on new Window
        //window.open(UtilsFiles.CreateURLFromBase64(axios_response, type));
    }
    public static generateGuid() {
        var result, i, j;
        result = '';
        for(j=0; j<32; j++) {
          if( j == 8 || j == 12 || j == 16 || j == 20) 
            result = result + '-';
          i = Math.floor(Math.random()*16).toString(16).toUpperCase();
          result = result + i;
        }
        return result;
      }

}