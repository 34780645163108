import { store } from '@/store/store';
import { paciente_conf_email } from '@/shared/dtos/paciente_conf_email';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';

@Module({
    namespaced: true,
    name: 'paciente_conf_emailModule',
    store,
    dynamic: true
})
class paciente_conf_emailModule extends VuexModule {
    public paciente_conf_emails: paciente_conf_email[] = [];
    public paciente_conf_email: paciente_conf_email = new paciente_conf_email();

    @Action({ commit: 'onGetpaciente_conf_emails' })
    public async getpaciente_conf_emails() {
        return await ssmHttpService.get(API.paciente_conf_email);
    }

    @Action({ commit: 'onGetpaciente_conf_emails' })
    public async getpaciente_conf_emails_activados(id: any) {
        return await ssmHttpService.get(API.paciente_conf_email + '/mails_activados?id=' + id);
    }


    @Action({ commit: 'onGetpaciente_conf_email' })
    public async getpaciente_conf_email(id: any) {
        return await ssmHttpService.get(API.paciente_conf_email + '/' + id);
    }

    @Action
    public async guardarpaciente_conf_email(paciente_conf_email: paciente_conf_email) {
        /*toJson() no existe pero existe en la extends de BaseDto*/
        await ssmHttpService.post(API.paciente_conf_email, paciente_conf_email.toJson());
    }

    @Action
    public async modificarpaciente_conf_email(paciente_conf_email: paciente_conf_email) {
        await ssmHttpService.put(API.paciente_conf_email+ '/' + paciente_conf_email.id_tipo_de_email, paciente_conf_email);
    }

    @Action
    public async modificarpaciente_conf_emails(paciente_conf_emails: paciente_conf_email[]) {
        
        await ssmHttpService.put(API.paciente_conf_email+ '/mails_activados_update/' , JSON.stringify(paciente_conf_emails));
    }


    @Action
    public async eliminarpaciente_conf_email(paciente_conf_email: paciente_conf_email) {
        await ssmHttpService.delete(API.paciente_conf_email + '/' + paciente_conf_email.id, null, false);
    }

    @Mutation
    public onGetpaciente_conf_emails(res: paciente_conf_email[]) {

        this.paciente_conf_emails = res ? res.map((x) => new paciente_conf_email(x)) : []
    }

    @Mutation
    public onGetpaciente_conf_email(res: paciente_conf_email) {
        this.paciente_conf_email = new paciente_conf_email(res);
    }

}
export default getModule(paciente_conf_emailModule);