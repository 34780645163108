import { store } from '@/store/store';
import { etiqueta } from '@/shared/dtos/etiqueta';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';
import { UtilsString } from '@/utils/utils-string';

@Module({
    namespaced: true,
    name: 'etiquetaModule',
    store,
    dynamic: true
})
class etiquetaModule extends VuexModule {
    public etiquetas: etiqueta[] = [];
    public etiqueta: etiqueta = new etiqueta();
    public etiquetas_for_vuetify: any[] = [];
    public fromid: number = 2;

    @Action({ commit: 'onGetetiquetas' })
    public async getetiquetas() {
        return await ssmHttpService.get(API.etiqueta);
    }

    @Action({ commit: 'onGetetiqueta' })
    public async getetiqueta(id: any) {
        return await ssmHttpService.get(API.etiqueta + '/' + id);
    }

    @Action({ commit: 'onGetetiquetas' })
    public async getetiquetas_from_tipo(id: number) {
        return await ssmHttpService.get(API.etiqueta + '/GetFromTipoEti/' + id);
    }

    @Action
    public async guardaretiqueta(etiqueta: etiqueta) {
        /*toJson() no existe pero existe en la extends de BaseDto*/
        await ssmHttpService.post(API.etiqueta, etiqueta.toJson());
        this.getetiquetas_from_tipo(this.fromid);
    }

    @Action
    public async modificaretiqueta(etiqueta: etiqueta) {
        await ssmHttpService.put(API.etiqueta, etiqueta);
        this.getetiquetas();
    }

    @Action
    public async eliminaretiqueta(etiqueta: etiqueta) {
        await ssmHttpService.delete(API.etiqueta + '/' + etiqueta.id, null, false);
        this.getetiquetas_from_tipo(this.fromid);
    }

    @Mutation
    public onGetetiquetas(res: etiqueta[]) {
        this.etiquetas_for_vuetify = [];
        for (let i = 0; i < res.length; i++) {
            this.etiquetas_for_vuetify.push({
                text: UtilsString.ValueOf(res[i].nombre),
                sistema: res[i].sistema,
                id: res[i].id,
            });
        }
        this.etiquetas = res ? res.map((x) => new etiqueta(x)) : [];
    }

    @Mutation
    public onGetetiqueta(res: etiqueta) {
        this.etiqueta = new etiqueta(res);
    }
    @Mutation
    public SetidFromGet(res: number) {
        this.fromid = res;
    }
    @Mutation
    public DeleteCaheEtiqueta(res: number) {
        this.etiquetas_for_vuetify.slice(res, 1);
    }
    @Mutation
    public AddCaheEtiqueta(res: any) {
        this.etiquetas_for_vuetify.push(res);
    }
}
export default getModule(etiquetaModule);