import { store } from '@/store/store';
import { hist_clinico } from '@/shared/dtos/Historial_clinico/hist_clinico';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';
import { UtilsFiles } from '@/utils/utils-files';
import { UtilsNotify } from '@/utils/utils-notify';

@Module({
    namespaced: true,
    name: 'hist_clinicoModule',
    store,
    dynamic: true
})
class hist_clinicoModule extends VuexModule {
    public hist_clinicos: hist_clinico[] = [];
    public hist_clinicos_diagnosticos: hist_clinico[] = [];
    public hist_clinicos_vacunas: hist_clinico[] = [];
    public hist_clinicos_medicamentos: hist_clinico[] = [];
    public hist_clinicos_cirujias: hist_clinico[] = [];
    public hist_clinicos_otros: hist_clinico[] = [];
    public hist_clinico: hist_clinico = new hist_clinico();

    @Action({ commit: 'onGethist_clinicos' })
    public async gethist_clinicos() {
        return await ssmHttpService.get(API.hist_clinico);
    }

    @Action({ commit: 'onGetDiagnosticos' })
    public async gethist_clinicos_diagnosticos(id_paciente: number) {
        return await ssmHttpService.get(API.hist_clinico + '/diagnosticos/' + id_paciente);
    }

    @Action({ commit: 'onGetVacunas' })
    public async gethist_clinicos_vacunas(id_paciente: number) {
        return await ssmHttpService.get(API.hist_clinico + '/vacunas/' + id_paciente);
    }

    @Action({ commit: 'onGetMedicamentos' })
    public async gethist_clinicos_medicamentos(id_paciente: number) {
        return await ssmHttpService.get(API.hist_clinico + '/medicamentos/' + id_paciente);
    }

    @Action({ commit: 'onGetCirujias' })
    public async gethist_clinicos_cirujias(id_paciente: number) {
        return await ssmHttpService.get(API.hist_clinico + '/cirujias/' + id_paciente);
    }

    @Action({ commit: 'onGetOtros' })
    public async gethist_clinicos_otros(id_paciente: number) {
        return await ssmHttpService.get(API.hist_clinico + '/otros/' + id_paciente);
    }

    @Action({ commit: 'onGethist_clinico' })
    public async gethist_clinico(id: any) {
        return await ssmHttpService.get(API.hist_clinico + '/' + id);
    }

    @Action({ commit: 'onGethist_clinicos' })
    public async gethist_clinico_del_paciente_by_tipo({id_paciente,id_tipo}:any) {
        return await ssmHttpService.get(API.hist_clinico + '/'+id_paciente+'/tipo/' + id_tipo);
    }

    @Action({ commit: 'onGetpdf_historial_clinico' })
    public async getpdf_historial_clinico({ id_tipo, id_paciente }: any) {
        return await ssmHttpService.get(API.hist_clinico + '/pdf_historial_clinico/' + id_tipo + '/' + id_paciente, null, false);
    }

    @Action({ commit: 'send_notifi_historial_clinico' })
    public async send_email_historial_clinico({ id_tipo, id_paciente }: any) {
        return await ssmHttpService.get(API.hist_clinico + '/send_historial_clinico/' + id_tipo + '/' + id_paciente, null, false);
    }

    @Action({ commit: 'onGetpdf_historial_clinico' })
    public async getpdf_historial_clinico_all_tipos(id_paciente: any) {
        return await ssmHttpService.get(API.hist_clinico + '/pdf_historial_clinico/' + id_paciente, null, false);
    }

    @Action({ commit: 'send_notifi_historial_clinico' })
    public async send_email_historial_clinico_all_tipos(id_paciente : any) {
        return await ssmHttpService.get(API.hist_clinico + '/send_historial_clinico/' +  id_paciente, null, false);
    }


    @Action
    public async guardarhist_clinico(hist_clinico: hist_clinico) {
        /*toJson() no existe pero existe en la extends de BaseDto*/
        await ssmHttpService.post(API.hist_clinico, hist_clinico.toJson(), false);
    }

    @Action
    public async modificarhist_clinico(hist_clinico: hist_clinico) {
        await ssmHttpService.put(API.hist_clinico, hist_clinico);
        this.gethist_clinicos();
    }

    @Action
    public async eliminarhist_clinico(hist_clinico: hist_clinico) {
        await ssmHttpService.delete(API.hist_clinico + '/' + hist_clinico.id, null, false);
        this.gethist_clinicos();
    }

    @Action
    public async eliminarhist_clinico_eliminar(hist_clinico: hist_clinico) {
        await ssmHttpService.put(API.hist_clinico + '/eliminar', hist_clinico.toJson(), false);
    }

    @Mutation
    public onGetDiagnosticos(res: hist_clinico[]) {
        this.hist_clinicos_diagnosticos = res ? res.map((x) => new hist_clinico(x)) : []
    }

    @Mutation
    public onGetVacunas(res: hist_clinico[]) {
        this.hist_clinicos_vacunas = res ? res.map((x) => new hist_clinico(x)) : []
    }

    @Mutation
    public onGetMedicamentos(res: hist_clinico[]) {
        this.hist_clinicos_medicamentos = res ? res.map((x) => new hist_clinico(x)) : []
    }

    @Mutation
    public onGetCirujias(res: hist_clinico[]) {
        this.hist_clinicos_cirujias = res ? res.map((x) => new hist_clinico(x)) : []
    }

    @Mutation
    public onGetOtros(res: hist_clinico[]) {
        this.hist_clinicos_otros = res ? res.map((x) => new hist_clinico(x)) : []
    }

    @Mutation
    public onGethist_clinicos(res: hist_clinico[]) {
        this.hist_clinicos = res ? res.map((x) => new hist_clinico(x)) : []
    }

    @Mutation
    public async onGetpdf_historial_clinico(response: any) {
        UtilsFiles.CreateURLFromBase64AndOpen(response, 'application/pdf');
    }
    @Mutation
    public async send_notifi_historial_clinico(response: any) {
        UtilsNotify.NotificacionPrimary('Historial clinico', 'Se he enviado correctamente el email', 'email');
    }



    @Mutation
    public onGethist_clinico(res: hist_clinico) {
        this.hist_clinico = new hist_clinico(res);
    }

}
export default getModule(hist_clinicoModule);