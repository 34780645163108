import { BaseDto } from '@/shared/dtos/base-dto';

export class hist_clinico extends BaseDto {
    public id_med_cir_dig_vac !: number;
    public id_tipohistclinico !: number;
    public id_paciente !: number;
    public fecha!:Date;
    public lotiene !: boolean;
    public descripcion !: string;

 } 
